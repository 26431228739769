import { createContext, ReactNode, useContext, useReducer } from 'react';

/* 
Expose un Custom Hook permettant de gérer un état à l'aide d'une fonction reducteur

Utilisation : 
* Modifier les sections "Définition du modèle" et "Fonction réducteur" selon les besoins
* Au plus haut requis de l'arbre des composants :
    <ModelProvider>
    ... les composants pouvant avoir besoin d'accéder au modèle
    </ModelProvider>

* Au sein d'un composant qui a besoin de lire le modèle : 
    let modele = useModel();
    if (!modele) {return null}

* Au sein d'un composant qui a besoin de modifier le modèle : 
    let dispatch = useModelDispatch()
    ...
    dispatch ( {type:'importeParticipants', payload:{...} })

*/

/*******
 * Définition du modèle
 * A adapter selon les besoins
 *******/

/* Un participant à une course */
export interface IParticipant {
    nom: string;
    prenom: string;
    sexe: string;
    niveau: string;
    dossard: number;
}

/* Une course */
export interface ICourse {
    nom: string;
    criteresNiveaux: string[];
    criteresSexe: string[];
}

/* Le modèle de données */
interface IModel {
    // Propriétés du modèle
    participants: IParticipant[];
    courses: ICourse[];
    arrivees: any; // Objet avec une propriété pour chaque course (nommée du nom de la course)
}

/* L'état initial du modèle */
const initialModel: IModel = {
    participants: [] as IParticipant[],
    courses: [],
    arrivees: {},
};

/* Propriétés d'une action (paramètre action passé à dispatch() ) */
interface IActionParam {
    type: 'importeParticipants' | 'modifieParticipant' | 'supprimeParticipant' | 'reset' | 'loadModel' | 'ajouteCourse' | 'arrivee';
    payload?: any;
}


function saveModel(newModel: IModel) {
    localStorage["crossmanager"] = JSON.stringify(newModel);
}

/*******
 * Code interne
 * Ces éléments n'ont pas besoin d'être modifiés pour utilisation
 *******/

/* Les contextes (ne sont pas exposés, sont utilisés uniquement par ce fichier) */
const ModelContext = createContext<IModel>(initialModel);
const ModelDispatchContext = createContext<((action: IActionParam) => void) | null>(null);

/* Paramètres du composant pour Typescript */
interface IWidthChildren {
    children: ReactNode
}

/*******
 * Eléments exposés (export) pour utilisation
 * Ces éléments n'ont pas besoin d'être modifiés pour utilisation
 *******/

/* Composant racine exporté (voir exemple d'utilisation en entête de fichier) */
export function ModelProvider({ children }: IWidthChildren) {
    const [model, dispatch] = useReducer(
        modeleReducer,
        initialModel
    );

    return (
        <ModelContext.Provider value={model}>
            <ModelDispatchContext.Provider value={dispatch}>
                {children}
            </ModelDispatchContext.Provider>
        </ModelContext.Provider>
    );
}

/* Export du custom hook pour accéder au modèle (voir exemple d'utilisation en entête de fichier) */
export function useModel() {
    return useContext(ModelContext);
}
/* Export du dispatcher pour agir sur le modèle (voir exemple d'utilisation en entête de fichier) */
export function useModelDispatch() {
    return useContext(ModelDispatchContext);
}

/*******
Fonction réducteur contenant la logique d'action sur le modèle
A adapter selon les besoins 
*******/
function modeleReducer(model: IModel, action: IActionParam) {
    switch (action.type) {
        case 'importeParticipants': {
            /* exemple de action.payload : 
            {
                    nomHeader: 'NOM',
                    prenomHeader: 'PRENOM',
                    sexeHeader: 'SEXE',
                    niveauHeader: 'DIV.',
                    dossardHeader: null,
                    donneesAImporter:[
                    {
                        "DIV.": "3A",
                        "NE(E) LE": 38810,
                        "NOM": "TARTEMPION",
                        "PRENOM": "Jaques",
                        "SEXE": "M"
                    },
                    {
                        "DIV.": "3A",
                        "NE(E) LE": 38596,
                        "NOM": "BON",
                        "PRENOM": "Jean",
                        "SEXE": "M"
                    },
                    ...
                ]
                */
            let nouveauxParticipants: IParticipant[] = action.payload.donneesAImporter.map((item: any, index: Number) => {
                return {
                    nom: item[action.payload.nomHeader],
                    prenom: item[action.payload.prenomHeader],
                    sexe: item[action.payload.sexeHeader],
                    niveau: item[action.payload.niveauHeader],
                    dossard: action.payload.dossardHeader === null ? 0 : item[action.payload.dossardHeader],
                } as IParticipant;
            });

            // Trie par niveau, nom, prénom (pour l'affectation des dossards)
            nouveauxParticipants.sort(trierParticipants);

            //Affecte les dossards
            let precedentNiveau = 0;
            let dossard = 0;
            //console.log(action.payload);

            nouveauxParticipants = nouveauxParticipants.map((item, index) => {
                let niveauActuel = parseInt(item.niveau.substring(0, 1));
                if (isNaN(niveauActuel)) { // Cas des CM2
                    niveauActuel = 7;
                }
                if (niveauActuel !== precedentNiveau) {
                    dossard = niveauActuel * 100 + 1 // 3A : 301, 6B: 601, CM2 : 701
                }
                item.dossard = dossard++;
                // Cas du dépassement d'élèves : 699 puis 6101 plutôt que 699 puis 700 (conserve les plages)
                if (dossard === (niveauActuel + 1) * 100) { // On passe de 699 à 700 (à noter qu'on n'aura donc jamais de 6000, on passe de 699 à 6101)
                    dossard = niveauActuel * 1000 + 100 + 1 // 3A : 3101, 6B: 6101, CM2 : 7101
                }
                precedentNiveau = niveauActuel;
                return item;
            });

            // Renvoi le nouveau modèle
            let newModel = {
                participants: [...model.participants, ...nouveauxParticipants].sort(trierParticipants),
                courses: [...model.courses],
                arrivees: { ...model.arrivees },
            } as IModel;
            saveModel(newModel);
            return newModel;
        }

        case 'reset':
            saveModel(initialModel);
            return initialModel;
            break;

        case 'loadModel':
            return localStorage["crossmanager"] ? JSON.parse(localStorage["crossmanager"]) : initialModel;
            break;

        case 'modifieParticipant': {
            let newParticipants = [...model.participants];
            let participant = newParticipants.find((item) => {
                return (("" + item.dossard) === action.payload.dossardID);
            });
            if (participant === undefined) {
                participant = {
                    nom: action.payload.nom,
                    prenom: action.payload.prenom,
                    sexe: action.payload.sexe,
                    niveau: action.payload.niveau,
                    dossard: action.payload.dossard,
                };
                newParticipants.push(participant);
            } else {
                participant.nom = action.payload.nom;
                participant.prenom = action.payload.prenom;
                participant.sexe = action.payload.sexe;
                participant.niveau = action.payload.niveau;
            }

            let newModel = {
                participants: [...newParticipants].sort(trierParticipants),
                courses: [...model.courses],
                arrivees: { ...model.arrivees },
            } as IModel;

            saveModel(newModel);
            return newModel;
        }
            break;

        case 'supprimeParticipant': {
            let newParticipantsSupp = [...model.participants];
            let index = newParticipantsSupp.findIndex((item) => {
                return (("" + item.dossard) === action.payload.dossardID);
            });
            if (index >= 0) {
                newParticipantsSupp.splice(index, 1);
            }
            let newModel = {
                participants: newParticipantsSupp.sort(trierParticipants),
                courses: [...model.courses],
                arrivees: { ...model.arrivees },
            } as IModel;

            saveModel(newModel);
            return newModel;
        }
            break;

        case 'ajouteCourse': {
            let newModel = {
                participants: [...model.participants],
                courses: [...model.courses, action.payload.course],
                arrivees: { ...model.arrivees },
            }
            saveModel(newModel);
            return newModel;
        }
            break;

        case 'arrivee': {
            let newModel = {
                participants: [...model.participants],
                courses: [...model.courses],
                arrivees: { ...model.arrivees },
            }
            if (newModel.arrivees[action.payload.courseID] === undefined) {
                newModel.arrivees[action.payload.courseID] = [];
            }
            if (newModel.arrivees[action.payload.courseID].indexOf(action.payload.dossard) < 0) {
                newModel.arrivees[action.payload.courseID].unshift(action.payload.dossard);
            }
            saveModel(newModel);
            return newModel;
        }
            break;

        default: {
            throw Error('Action non implémentée: ' + action.type);
        }
    }
}

/* Fonctions utilitaires */

/* Trie les participants par niveau, nom, prénom (initialement pour simplifier l'impression des dossards) */
function trierParticipants(a: IParticipant, b: IParticipant): number {
    // Niveau
    if (a.niveau > b.niveau) {
        return 1;
    }
    if (a.niveau < b.niveau) {
        return -1;
    }
    // Nom
    if (a.nom > b.nom) {
        return 1;
    }
    if (a.nom < b.nom) {
        return -1;
    }
    // Prénom
    if (a.prenom > b.prenom) {
        return 1;
    }
    if (a.prenom < b.prenom) {
        return -1;
    }

    return 0;
};

export function isParticipantAlreadyImported(model: IModel, participantTest: IParticipant) {
    let participants = model.participants;
    let existeDeja = false;
    participants.forEach((item, index) => {
        if (
            item.nom === participantTest.nom &&
            item.prenom === participantTest.prenom &&
            item.sexe === participantTest.sexe &&
            item.niveau === participantTest.niveau) {
            existeDeja = true;
        }
    });
    return existeDeja;
}

export function listeNiveaux(model: IModel): string[] {
    let niveaux: string[] = [];
    let dernierNiveau = '';
    model.participants.forEach((participant) => {
        let nouveauNiveau = participant.niveau.substring(0, 1);
        if (nouveauNiveau === 'C') {
            nouveauNiveau = 'CM2';
        }
        if (dernierNiveau !== nouveauNiveau) {
            niveaux.push(nouveauNiveau);
            dernierNiveau = nouveauNiveau;
        }
    });
    return niveaux;
}