import { useNavigate, useParams } from "react-router-dom"
import { useModel, useModelDispatch } from "../../Components/ModeleProvider";
import { useEffect, useState } from "react";
import BoutonRetour from "../../Components/BoutonRetour";

export default function ParticipantsDetail() {
    const { dossardID } = useParams();
    const navigate = useNavigate();

    const model = useModel();
    const dispatch = useModelDispatch();

    const [charge, setCharge] = useState(false);
    const [modeEdition, setModeEdition] = useState(dossardID === '0');

    const [nom, setNom] = useState('');
    const [prenom, setPrenom] = useState('');
    const [sexe, setSexe] = useState('');
    const [niveau, setNiveau] = useState('');
    const [dossard, setDossard] = useState(0);

    useEffect(() => {
        if (dispatch) {
            dispatch({ type: 'loadModel' });
        }
    }, []);

    useEffect(() => {
        setCharge(true);
    }, [model]);

    useEffect(() => {
        const participant = model.participants.find((item) => {
            return (("" + item.dossard) === dossardID);
        });
        setNom(participant?.nom || '');
        setPrenom(participant?.prenom || '');
        setSexe(participant?.sexe || '');
        setNiveau(participant?.niveau || '');
        setDossard(participant?.dossard || 0);
    }, [charge]);

    return <>
        <h1><BoutonRetour/>Participant
            <p className='actions'>
                {
                    modeEdition === false && <input type='button' value='Modifier' onClick={() => setModeEdition(true)}></input>
                }
                {modeEdition && <input type='button' value='Annuler' onClick={() => { setModeEdition(false); navigate(-1) }}></input>}
                {modeEdition && <input type='button' value='Valider' onClick={() => {
                    if (nom === '') {
                        alert('Veuillez saisir le nom');
                        return;
                    }
                    if (prenom === '') {
                        alert('Veuillez saisir le prénom');
                        return;
                    }
                    if ((sexe !== 'F') && (sexe !== 'M')) {
                        alert('Veuillez saisir le sexe');
                        return;
                    }
                    if (niveau === '') {
                        alert('Veuillez saisir le niveau');
                        return;
                    }
                    if (dossard === 0) {
                        alert('Veuillez saisir le dossard');
                        return;
                    }
                    if (parseInt(dossardID ?? '0') !== dossard) {
                        if (model.participants.find((item) => {
                            return (item.dossard === dossard);
                        })) {
                            alert('Ce dossard est déjà saisi');
                            return;
                        }
                    }
                    dispatch && dispatch({
                        type: 'modifieParticipant',
                        payload: { nom, prenom, sexe, niveau, dossard, dossardID }
                    });
                    navigate(-1)
                }}></input>}

                {modeEdition && dossardID !== '0' && <input type='button' value='Supprimer' onClick={() => {
                    dispatch && dispatch({
                        type: 'supprimeParticipant',
                        payload: { dossardID }
                    });
                    navigate(-1)
                }}></input>}
            </p>

        </h1>

        <ul className="champsParticipant">
            <li><span>Nom</span>
                <input type='text' value={nom} onChange={(event) => setNom(event.target.value)} disabled={!modeEdition}></input>
            </li>
            <li><span>Prénom</span>
                   <input type='text' value={prenom} onChange={(event) => setPrenom(event.target.value)} disabled={!modeEdition}></input>
            </li>
            <li><span>Sexe</span>
                    <input type='text' value={sexe} onChange={(event) => setSexe(event.target.value)} disabled={!modeEdition}></input>(F ou M)
            </li>
            <li><span>Niveau</span>
                    <input type='text' value={niveau} onChange={(event) => setNiveau(event.target.value)} disabled={!modeEdition}></input>(exemple : 3A ou CM2)
            </li>
            <li><span>Dossard</span>
                    <input type='number' value={dossard} onChange={(event) => setDossard(parseInt(event.target.value))} disabled={!modeEdition}></input>
            </li>
        </ul>
    </>
}
