import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
//import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Accueil from './routes/accueil';
import Listings from './routes/listings';
import ParticipantsListe from './routes/participants/liste';
import CoursesListe from './routes/courses/liste';
import ParticipantsImport from './routes/participants/import';
import { ModelProvider } from './Components/ModeleProvider';
import ParticipantsDetail from './routes/participants/detail';
import Arrivee from './Components/Arrivee';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Accueil />,
  },
  {
    path: "/participants",
    element: <ParticipantsListe />,
  },
  {
    path: "/participants/import",
    element: <ParticipantsImport />,
  },
  {
    path: "/participants/:dossardID",
    element: <ParticipantsDetail />,
  },
  {
    path: "/courses",
    element: <CoursesListe />,
  },
  {
    path: "/courses/:courseID",
    element: <Arrivee />,
  },
  {
    path: "/listings",
    element: <Listings />,
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <ModelProvider>
      <RouterProvider router={router} />
    </ModelProvider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
//serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
