import { useNavigate } from "react-router-dom"
import { useModel, useModelDispatch } from "../../Components/ModeleProvider";
import { useEffect } from "react";
import BoutonRetour from "../../Components/BoutonRetour";

export default function ParticipantsListe() {
    const navigate = useNavigate();
    const model = useModel();
    const dispatch = useModelDispatch();
    useEffect(() => { dispatch && dispatch({ type: 'loadModel' }) }, []);

    return (
        <>
            <h1><BoutonRetour/>{model.participants.length} participants</h1>
            <p className='actions'><a href='/listings'>Impression...</a>
            <button onClick={() => navigate('/participants/import')}>Importer un fichier excel...</button>
            <button onClick={() => navigate('/participants/0')}>Ajouter...</button></p>
            <table>
                <thead>
                    <tr>
                    <th>Dossard</th>
                    <th>Niveau</th>
                    <th>Nom</th>
                    <th>Prénom</th>
                    <th>Sexe</th>
                    </tr>
                </thead>
                <tbody>
                    {model.participants.map((item, index) => {
                        return (<tr key={index}>
                            <td>{item.dossard}</td>
                            <td>{item.niveau}</td>
                            <td><a href={`/participants/${item.dossard}`}>{item.nom}</a></td>
                            <td>{item.prenom}</td>
                            <td>{item.sexe}</td>
                        </tr>
                        );
                    })
                    }
                </tbody>
            </table>

        </>
    )
}