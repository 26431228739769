import { useEffect } from "react";
import { useModel, useModelDispatch } from "../../Components/ModeleProvider";
import { useNavigate } from "react-router-dom";
import BoutonRetour from "../../Components/BoutonRetour";
import CourseDetail from "../../Components/CourseDetail";

export default function CoursesListe() {
    const model = useModel();
    const navigate = useNavigate();
    const dispatch = useModelDispatch();
    useEffect(() => { dispatch && dispatch({ type: 'loadModel' }) }, []);
    return <>
        <h1><BoutonRetour /> Liste des courses </h1>
        {model.courses.map((item) => {
            return  <CourseDetail course={item} />;
        })
        }
        <CourseDetail course={{ nom: '', criteresNiveaux: [], criteresSexe: [] }} />
    </>;
}