import { useNavigate } from "react-router-dom";
import { ICourse, listeNiveaux, useModel, useModelDispatch } from "./ModeleProvider";
import { useEffect, useState } from "react";

type ICourseParams = {
    course: ICourse;
};

export default function CourseDetail({ course }: ICourseParams) {
    const [nouvelleCourse, setNouvelleCourse] = useState(course.nom === '');
    const navigate = useNavigate();
    const model = useModel();
    const dispatch = useModelDispatch();
    const [nom, setNom] = useState(course.nom);
    const [criteresNiveaux, setCriteresNiveaux] = useState(course.criteresNiveaux);
    const [criteresSexe, setCriteresSexe] = useState(course.criteresSexe);

    useEffect(() => { dispatch && dispatch({ type: 'loadModel' }) }, []);

    function onNiveauChange() {
        let niveaux: string[] = [];
        listeNiveaux(model).map((niveau) => {
            if ((document.getElementById(niveau) as HTMLInputElement).checked) {
                niveaux.push(niveau);
            }
            return niveau;
        }
        );
        setCriteresNiveaux(niveaux);

    }

    function onSexeChange() {
        let sexes: string[] = [];
        if ((document.getElementById('sexeF') as HTMLInputElement).checked) {
            sexes.push('F');
        }
        if ((document.getElementById('sexeM') as HTMLInputElement).checked) {
            sexes.push('M');
        }
        setCriteresSexe(sexes);
    }


    function onAjouterCourse() {
        //TODO: vérifier la saisie !
        if (nom === '') {
            alert('Veuillez donner un nom à cette course');
            return;
        }

        if (criteresNiveaux.length === 0) {
            alert('Veuillez sélectionner au moins 1 niveau');
            return;
        }

        if (criteresSexe.length === 0) {
            alert('Veuillez sélectionner au moins 1 sexe');
            return;
        }

        let course = {
            nom: nom,
            criteresNiveaux: criteresNiveaux,
            criteresSexe: criteresSexe,
        };
        dispatch && dispatch({ type: 'ajouteCourse', payload: { course } });

    }
    return <>
        {listeNiveaux(model).length === 0 && <a href='/participants/import'>Ajouter des participants pour commencer</a>}

        {nouvelleCourse && listeNiveaux(model).length > 0 && (<>Ajouter une course :
            <ul>
                <li>
                    Nom : <input type='text' value={nom} onChange={(event) => setNom(event.target.value)}></input>
                </li>
                <li>Critère(s) de niveaux : {listeNiveaux(model).map((niveau) => {
                    return <><input type='checkbox' value={niveau} onChange={onNiveauChange} id={niveau}></input> <label htmlFor={niveau}>{niveau}</label></>
                }

                )}</li>
                <li>Critère(s) de sexe : <input type='checkbox' value='F' onChange={onSexeChange} id='sexeF'></input> <label htmlFor="sexeF">F</label>
                    <input type='checkbox' value='M' onChange={onSexeChange} id='sexeM'></input><label htmlFor="sexeM">M</label>
                </li>
                <input type='submit' value='Ajouter' onClick={onAjouterCourse}></input>
            </ul></>)}



        {!nouvelleCourse && (<div className="coursePrete">
            {course.nom}    <a href={`/courses/${course.nom}`}>Saisir les arrivées</a>
        </div>)}
    </>
}