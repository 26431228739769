import { useEffect } from "react";
import { useModelDispatch } from "../Components/ModeleProvider";

export default function Accueil() {
  const dispatch = useModelDispatch();
  useEffect(()=> {dispatch && dispatch({type:'loadModel'})}, []);

    return (
      <>
          <nav>
            <ul>
              <li>
                <a href={`/participants`}>Gérer les participants</a>
              </li>
              <li>
                <a href={`/courses`}>Gérer les courses</a>
              </li>
              <li>
                <a href={`/listings`}>Obtenir les listings (pdf)</a>
              </li>
            </ul>
          </nav>
      </>
    );
  }