import { useEffect, useState } from 'react';
import * as XLSX from 'xlsx'; // npm install xlsx
import { isParticipantAlreadyImported, useModel, useModelDispatch } from '../../Components/ModeleProvider';
import { useNavigate } from "react-router-dom";
import { error } from 'console';
import BoutonRetour from '../../Components/BoutonRetour';

export default function ParticipantsImport() {
    const [fichierChoisi, setFichierChoisi] = useState(false); // Un fichier a-t-il été choisi par l'utilisateur ?
    const [nbColonnes, setNbColonnes] = useState(0); // Nombre de colonnes trouvées dans le fichier
    const [nbLignes, setNbLignes] = useState(0); // Nombre de lignes trouvées dans le fichier (hors ligne d'entête)
    const [erreur, setErreur] = useState(""); // Erreur durant le traitement du fichier
    const [headersTrouves, setHeadersTrouves] = useState([] as string[]); // Liste des noms d'entêtes extraits du fichier xlsx
    const model = useModel();
    const dispatch = useModelDispatch();
    useEffect(()=> {dispatch && dispatch({type:'loadModel'})}, []);

    const navigate = useNavigate();

    // Affectations possibles de chaque entête au type de la donnée gérée dans CrossManager
    const [nomHeader, setNomHeader] = useState<string | null>(null);
    const [prenomHeader, setPrenomHeader] = useState<string | null>(null);
    const [sexeHeader, setSexeHeader] = useState<string | null>(null);
    const [niveauHeader, setNiveauHeader] = useState<string | null>(null);
    const [dossardHeader, setDossardHeader] = useState<string | null>(null);

    // Données à importer (contenu du fichier au format json)
    const [donneesAImporter, setDonneesAImporter] = useState<any>(null);

    function onFileChosen(event: React.ChangeEvent<HTMLInputElement>) {
        // Efface le précédent mapping
        setNomHeader(null);
        setPrenomHeader(null);
        setSexeHeader(null);
        setNiveauHeader(null);

        setFichierChoisi(false);
        setErreur("");

        if (event.target.files === null) {
            setFichierChoisi(false);
            return;
        }
        try {
            let file = event.target.files[0];
            if (file) {
                const reader = new FileReader();
                reader.onload = (evt) => {
                    let workbook = XLSX.read(evt.target?.result);
                    /* 
                       Code basé sur https://medium.com/@gb.usmanumar/how-to-import-data-from-excel-xlsx-in-react-js-f486a600dc9f
                   */
                    let ref = workbook.Sheets[workbook.SheetNames[0]]["!ref"];
                    let donnees = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]]);
                    /* donnees est un array de la forme suivante :
                    [
                        {
                            "DIV.": "3A",
                            "NE(E) LE": 38810,
                            "NOM": "TARTEMPION",
                            "PRENOM": "Jaques",
                            "SEXE": "M"
                        },
                        {
                            "DIV.": "3A",
                            "NE(E) LE": 38596,
                            "NOM": "BON",
                            "PRENOM": "Jean",
                            "SEXE": "M"
                        },
                        ...
                    ]
                    */

                    let nbColonnes = Object.keys(donnees[0] as any).length;

                    //Supprime les lignes parasites de fin de fichier ( ex : {NOM:'   '})
                    if (donnees.length > 1) {
                        let i = 1; // Commense à la 2°ligne
                        while (i < donnees.length) {
                            if (Object.keys(donnees[i] as any).length !== nbColonnes) {
                                donnees.splice(i, 1);
                            } else {
                                i++;
                            }
                        }
                    }
                    if (donnees.length === 0) {
                        setErreur("Aucune données trouvées - assurez-vous de sélectionner un fichier Excel");
                        return;
                    }

                    setNbColonnes(nbColonnes);
                    setNbLignes(donnees.length);
                    // Au minimum Nom, Prénom, Niveau, Sexe
                    if (nbColonnes < 4) {
                        setErreur("Le nombre de colonnes est insuffisant - assurez-vous de sélectionner un fichier Excel");
                        return;
                    }

                    let headers = Object.keys(donnees[0] as any);

                    // Tente de présélectionner les headers
                    let nom = false;
                    let prenom = false;
                    let sexe = false;
                    let niveau = false;
                    let dossard = false;
                    headers.forEach((item) => {
                        if ((item.substring(0, 3).toUpperCase() === 'NOM') && (nom === false)) {
                            nom = true;
                            setNomHeader(item);
                        }
                        if ((item.substring(0, 2).toUpperCase() === 'PR') && (item.substring(3, 6).toUpperCase() === 'NOM') && (prenom === false)) {
                            prenom = true;
                            setPrenomHeader(item);
                        }
                        if ((item.substring(0, 3).toUpperCase() === 'SEX') && (sexe === false)) {
                            sexe = true;
                            setSexeHeader(item);
                        }
                        if ((item.substring(0, 4).toUpperCase() === 'DIV.') && (niveau === false)) {
                            niveau = true;
                            setNiveauHeader(item);
                        }
                        if ((item.substring(0, 3).toUpperCase() === 'NIV') && (niveau === false)) {
                            niveau = true;
                            setNiveauHeader(item);
                        }
                        if ((item.substring(0, 7).toUpperCase() === 'DOSSARD') && (dossard === false)) {
                            dossard = true;
                            setDossardHeader(item);
                        }

                    })

                    headers.unshift('...');
                    setHeadersTrouves(headers);
                    setDonneesAImporter(donnees);
                    setFichierChoisi(true);
                };
                reader.readAsArrayBuffer(file);
            }
        } catch (e) {
            setFichierChoisi(false)
            console.log(e);
            setErreur("Une erreur est survenue - assurez-vous de sélectionner un fichier Excel : " + e);
        }

    }

    // L'utilisateur clique sur "Importer"
    const onImporterClick = () => {
        if (dispatch === null) {
            alert('Erreur #1');
            return;
        }
        if (donneesAImporter === null) { // sécurité
            alert('Données manquantes - Veuillez choisir un fichier à importer');
            return;
        }
        let tousLesChampsSontMappes = "";
        if ((nomHeader === null) || (nomHeader === '')) {
            tousLesChampsSontMappes += 'Nom, ';
        }
        if ((prenomHeader === null) || (prenomHeader === '')) {
            tousLesChampsSontMappes += 'Prénom, ';
        }
        if ((sexeHeader === null) || (sexeHeader === '')) {
            tousLesChampsSontMappes += 'Sexe, ';
        }
        if ((niveauHeader === null) || (niveauHeader === '')) {
            tousLesChampsSontMappes += 'Niveaux, ';
        }

        if (tousLesChampsSontMappes !== "") {
            tousLesChampsSontMappes = tousLesChampsSontMappes.substring(0, tousLesChampsSontMappes.length - 2);

            alert(`Correspondance manquante - Veuillez choisir les colonnes à associer à ${tousLesChampsSontMappes}`);

        } else {
            //Vérifie une éventuelle double-importation en testant le premier participant trouvé
            let participantTest = {
                nom: donneesAImporter[0][nomHeader ?? 'NOM'],
                prenom: donneesAImporter[0][prenomHeader ?? 'PRENOM'],
                sexe: donneesAImporter[0][sexeHeader ?? 'SEXE'],
                niveau: donneesAImporter[0][niveauHeader ?? 'DIV.'],
                dossard: 0,// N'est pas vérifié par isParticipantAlreadyImported
            }
            if (isParticipantAlreadyImported(model, participantTest)) {
                alert('Ce fichier a déjà été importé');
            } else {
                dispatch({
                    type: 'importeParticipants',
                    payload: {
                        nomHeader: nomHeader,
                        prenomHeader: prenomHeader,
                        sexeHeader: sexeHeader,
                        niveauHeader: niveauHeader,
                        dossardHeader: dossardHeader,
                        donneesAImporter: donneesAImporter,
                    }
                });
                alert(`${donneesAImporter.length} participants importés`)
                // navigate('/participants'); // Revient sur la liste des participants
                navigate(-1); // Revient sur la liste des participants
            }
        }
    }

    return (
        <>
            <h1><BoutonRetour/>Import des participants</h1>
            <p>Veuillez choisir un fichier Excel contenant les participants à importer (Les noms des colonnes sont libres)</p>
            <input type='file' onChange={onFileChosen} onAbort={() => setFichierChoisi(false)}></input>
            <p className='erreur'>{erreur}</p>
            {fichierChoisi && (
                <div>{nbColonnes} colonnes trouvées, veuillez les faire correspondre aux informations :
                    <ul className='listeMapping'>
                        <li><label htmlFor='nom'>Nom :</label> <select id='nom' value={nomHeader ?? ''} onChange={(event) => setNomHeader(event.target.value)}>
                                {
                                    headersTrouves.map((item) => <option value={item} key={item}>{item}</option>)
                                }
                            </select>
                        </li>

                        <li><label htmlFor='prenom'>Prénom :</label> <select id='prenom' value={prenomHeader ?? ''} onChange={(event) => setPrenomHeader(event.target.value)}>
                                {
                                    headersTrouves.map((item) => <option value={item} key={item}>{item}</option>)
                                }
                            </select>
                        </li>

                        <li><label htmlFor='sexe'>Sexe :</label> <select  id='sexe' value={sexeHeader ?? ''} onChange={(event) => setSexeHeader(event.target.value)}>
                                {
                                    headersTrouves.map((item) => <option value={item} key={item}>{item}</option>)
                                }
                            </select>
                        </li>

                        <li><label htmlFor='niveau'>Niveau :</label> <select  id='niveau' value={niveauHeader ?? ''} onChange={(event) => setNiveauHeader(event.target.value)}>
                                {
                                    headersTrouves.map((item) => <option value={item} key={item}>{item}</option>)
                                }
                            </select>
                        </li>
                    </ul>
                    <button onClick={onImporterClick}>Importer {nbLignes} participants</button>
                </div>
            )}
        </>)
}