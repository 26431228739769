import { ChangeEvent, ChangeEventHandler, HtmlHTMLAttributes, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useModel, useModelDispatch } from "./ModeleProvider";

export default function Arrivee() {
    const { courseID } = useParams();
    const model = useModel();
    const dispatch = useModelDispatch();

    const navigate = useNavigate();
    const [dossard, setDossard] = useState(0);
    const [erreur, setErreur] = useState('');

    useEffect(() => { dispatch && dispatch({ type: 'loadModel' }) }, []);

    function onDossardChange(event: ChangeEvent<HTMLInputElement>) {
        let dossardSaisi = 0;
        setErreur('');
        if (event.target.value !== '') {
            dossardSaisi = parseInt(event.target.value);
        }
        setDossard(dossardSaisi);
        let participant = model.participants.find((item) => {
            return item.dossard === dossardSaisi;
        });
        if (participant === undefined) {
            if (dossardSaisi > 100) {setErreur('Dossard inconnu');} // (>100 pour attendre les 3 chiffres du dossard)
            return;
        } else {
            //Ce participant fait-il partie des critères de cette course ?
            let course = model.courses.find((item) => item.nom === courseID);
            if (course === undefined) {
                setErreur('Erreur critique : impossible de trouver la course');
                return;
            }
            let niveauParticipant = participant.niveau.substring(0, 1);
            if (niveauParticipant === 'C') {
                niveauParticipant = 'CM2';
            }
            if (course.criteresNiveaux.indexOf(niveauParticipant) < 0) {
                setErreur('Ce dossard ne fait pas partie de cette course');
                return;
            }
            if (course.criteresSexe.indexOf(participant.sexe) < 0) {
                setErreur('Ce dossard ne fait pas partie de cette course');
                return;
            }
            if (model.arrivees[course.nom] !== undefined) {
                if (model.arrivees[course.nom].indexOf(dossardSaisi) >= 0) {
                    setErreur('Dossard déjà saisi');
                    return;
                }
            }
            dispatch && dispatch({
                type: 'arrivee', payload: {
                    courseID: course.nom,
                    dossard: dossardSaisi,
                }
            });
            setDossard(0);
        }

    }


    return (
        <>
            <h1><input type='button' onClick={() => navigate(-1)} value='&lt;'></input>{courseID}</h1>

            <input type='text' onChange={onDossardChange} value={dossard}></input>
            <span className="erreur">{erreur}</span>
            <ul>
                {courseID && model.arrivees[courseID] && model.arrivees[courseID].map((item: number, index: number) =>
                    <li>{index + 1} : {item}</li>
                )}
            </ul>
        </>);
}
