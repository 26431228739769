import { useNavigate, useSearchParams } from "react-router-dom"
import { useModel, useModelDispatch } from "../Components/ModeleProvider";
import { PDFDocument, PDFFont, PDFPage, StandardFonts, rgb } from 'pdf-lib'
import { useEffect, useState } from "react";
import BoutonRetour from "../Components/BoutonRetour";

export default function Listings() {
    const navigate = useNavigate();
    const model = useModel();
    const dispatch = useModelDispatch();
    const [csv, setCsv] = useState('');

    const [pdfDossardsURI, setPdfDossardsURI] = useState<string | null>(null);

    useEffect(() => {
        dispatch && dispatch({ type: 'loadModel' })
    }, []);


    // Regénère les pdfs lorsque le model change
    useEffect(() => {
        regenerPDFs();
    }, [model]);

    const regenerPDFs = async () => {
        // ATTENTION beaucoup de bibliothèques ne fonctionnent pas. Celle-ci fonctionne :
        // https://pdf-lib.js.org/#examples
        // npm install pdf-lib

        let nbElevesParPageMax = 60;

        // Dossards
        let timesRomanFont: PDFFont;
        PDFDocument.create()
            .then(async (pdfDoc) => {
                timesRomanFont = await pdfDoc.embedFont(StandardFonts.TimesRoman);
                return pdfDoc;
            })
            .then((pdfDoc) => {
                let dernierNiveau = '';
                let nbElevesImprimes = 0;
                let page: PDFPage | null = null;
                const fontSizeHeader = 14;
                const fontSizeLine = 12;
                let x = [50, // Niveau
                    100, // Dossard
                    150, // Nom
                    400, // Prénom
                    500]; // Sexe
                let yBase = 770;
                let colorHeader = rgb(0, 0.53, 0.71);
                let colorLine = rgb(0, 0, 0);
                // Les participants sont maintenus triés par Classe, Nom, Prénom (et donc par dossard, sauf modif)
                // (0, 0) correspond à en bas à gauche
                model.participants.map((item, index) => {
                    nbElevesImprimes++;
                    // Besoin d'une nouvelle page ?
                    if (dernierNiveau !== item.niveau
                        || nbElevesImprimes >= nbElevesParPageMax) {
                        page = pdfDoc.addPage();
                        dernierNiveau = item.niveau;
                        nbElevesImprimes = 1;
                        page.drawText('Niveau', {
                            x: x[0],
                            y: yBase + 3,
                            size: fontSizeHeader,
                            font: timesRomanFont,
                            color: colorHeader,
                        });
                        page.drawText('Dossard', {
                            x: x[1],
                            y: yBase + 3,
                            size: fontSizeHeader,
                            font: timesRomanFont,
                            color: colorHeader,
                        });
                        page.drawText('Nom', {
                            x: x[2],
                            y: yBase + 3,
                            size: fontSizeHeader,
                            font: timesRomanFont,
                            color: colorHeader,
                        });
                        page.drawText('Prénom', {
                            x: x[3],
                            y: yBase + 3,
                            size: fontSizeHeader,
                            font: timesRomanFont,
                            color: colorHeader,
                        });
                        page.drawText('Sexe', {
                            x: x[4],
                            y: yBase + 3,
                            size: fontSizeHeader,
                            font: timesRomanFont,
                            color: colorHeader,
                        });

                        // Titre
                        page.drawText(`Dossards ${item.niveau}`, {
                            x: x[0],
                            y: yBase + 7 + fontSizeHeader,
                            size: fontSizeHeader,
                            font: timesRomanFont,
                            color: colorHeader,
                        });

                        // Grille horizontale
                        page.drawLine({ start: { x: x[0] - 2, y: yBase - 2 }, end: { x: 550, y: yBase - 2 }, color: rgb(0, 0, 0) });
                        page.drawLine({ start: { x: x[0] - 2, y: yBase + 2 + fontSizeHeader}, end: { x: 550, y: yBase + 2 + fontSizeHeader }, color: rgb(0, 0, 0) });
                        page.drawLine({ start: { x: x[0] - 2, y: 55 }, end: { x: 550, y: 55 }, color: rgb(0, 0, 0) });

                        // Grille verticale
                        x.map((item, index) => {
                            page?.drawLine({ start: { x: x[index] - 2, y: yBase + fontSizeHeader + 2 }, end: { x: x[index] - 2, y: 55 }, color: rgb(0, 0, 0) });
                        });
                        page.drawLine({ start: { x: x[4] + 50, y: yBase + fontSizeHeader + 2 }, end: { x: x[4] + 50, y: 55 }, color: rgb(0, 0, 0) });
                    }
                    if (!page) {
                        return;
                    }

                    // Participant
                    let yLigne = yBase - (fontSizeLine * nbElevesImprimes) - (fontSizeHeader - fontSizeLine);
                    page.drawText(item.niveau, {
                        x: x[0],
                        y: yLigne,
                        size: fontSizeHeader,
                        font: timesRomanFont,
                        color: colorLine,
                    });
                    page.drawText("" + item.dossard, {
                        x: x[1],
                        y: yLigne,
                        size: fontSizeHeader,
                        font: timesRomanFont,
                        color: colorLine,
                    });
                    page.drawText(item.nom, {
                        x: x[2],
                        y: yLigne,
                        size: fontSizeHeader,
                        font: timesRomanFont,
                        color: colorLine,
                    });
                    page.drawText(item.prenom, {
                        x: x[3],
                        y: yLigne,
                        size: fontSizeHeader,
                        font: timesRomanFont,
                        color: colorLine,
                    });
                    page.drawText(item.sexe, {
                        x: x[4],
                        y: yLigne,
                        size: fontSizeHeader,
                        font: timesRomanFont,
                        color: colorLine,
                    });
                    // Ligne de séparation toutes les 5 lignes
                    if (nbElevesImprimes %5 === 0) {
                    page.drawLine({ start: { x: x[0] - 2, y: yLigne - 1 }, end: { x: 550, y: yLigne - 1 }, color: rgb(0.8, 0.8, 0.8) });
                    }

                });
                return pdfDoc;
            }).then((pdfDoc) => {
                pdfDoc.saveAsBase64().then((base64) => setPdfDossardsURI(`data:application/pdf;base64,${base64}`));
            });


        // CSV (pour 2024)
        let txt = model.participants.map((item) => {
            return `${item.dossard}\t${item.nom}\t${item.prenom}\t${item.niveau}\t${item.sexe}`;
        });
        setCsv(txt.join('\n'));
    }

    return (
        <>
            <h1><BoutonRetour/>Listings</h1>
            <ul>
                <li>{pdfDossardsURI && <a href={pdfDossardsURI} download='Dossards.pdf' >Dossards par classe ({model.participants.length})</a>}
                </li>
            </ul>
            Données pour ancienne version : <textarea defaultValue={csv}></textarea>
        </>
    );
}